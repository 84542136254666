
export default {
  name: 'TemplateLanding',
  data() {
    return {
      sections: []
    }
  },
  head() {
    return {
      title: this.$store.state.base.meta.generalConfig.baseTitle,
      titleTemplate: `%s - ${this.$store.state.base.meta.landingConfig.meta.title}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.$tdb(this.$store.state.base.meta.landingConfig.meta.description)
        }
      ],
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.$store.state.base.meta.generalConfig.favicon
        }
      ]
    }
  },
  computed: {
    meta() {
      return this.$store.state.base.meta.landingConfig.meta
    },
    sectionsData() {
      return JSON.parse(JSON.stringify(this.$store.state.base.meta.landingConfig.sections))
    }
  },
  created() {
    this.sortSections()
  },
  methods: {
    sortSections() {
      this.sections = this.sectionsData.sort((a, b) => a.order_no - b.order_no)
    }
  }
}
